import { mapPrice } from "../../shared-logic/utils/priceUtils";
import { showLegalBannerDisclaimer } from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { showAemMonthlyRates } from "../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent } from "../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import type { LegalBannerDisclaimerDataType } from "../../../shared-logic/types/FinanceTypes";
import { createFinanceMinMax } from "../../../shared-logic/utils/legalBannerDisclaimerUtils";
import { useGradeSelectorSelector } from "../redux/hooks";
import { useFilteredGradeBodyTypes } from "../utils/filterHooks";
import { getMotorizationData } from "../utils/gradeUtils";

const useDisclaimerContent = (): LegalBannerDisclaimerDataType | null => {
    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);
    const { code: modelCode } = useGradeSelectorSelector((state) => state.gradeSelectorModel.model);
    const gradeBodyTypes = useFilteredGradeBodyTypes();
    const { state: financeState, cars } = useGradeSelectorSelector(({ gradeSelectorFinance }) => gradeSelectorFinance);
    const monthlyEnabled = showAemMonthlyRates(commonSettings, AemFmComponent.GradeSelector, "new", modelCode);
    const hasLegalBannerDisclaimer = showLegalBannerDisclaimer(commonSettings);

    if (!monthlyEnabled || financeState !== "complete" || !hasLegalBannerDisclaimer) {
        return null;
    }

    const filteredGradeBodyTypes = gradeBodyTypes.filter((gradeBodyType) => {
        const { wheeldrive } = getMotorizationData(gradeBodyType);
        const colourId = gradeBodyType.selectedColourId || wheeldrive.colors[0].id || "";
        const carFinance = cars[`${wheeldrive.car.CarID}-${colourId}`];
        return !!carFinance; // Keep only items where carFinance exists
    });
    const tableInput = filteredGradeBodyTypes.map((gradeBodyType) => {
        const { wheeldrive } = getMotorizationData(gradeBodyType);
        const colourId = gradeBodyType.selectedColourId || wheeldrive.colors[0].id || "";
        const { price, car } = wheeldrive;
        const { financeInfo } = cars[`${car.CarID}-${colourId}`];

        return {
            price: mapPrice(price, financeInfo),
            finance: financeInfo,
        };
    });

    return createFinanceMinMax(tableInput);
};

export default useDisclaimerContent;
