import type { FinanceInfoDisclaimerType, CommonFinanceInfoType } from "../../../common-deprecated/types/CommonTypes";
import type { LegalBannerDisclaimerDataType } from "../types/FinanceTypes";

export type CreateFinanceMinMaxType<T> = { finance?: CommonFinanceInfoType; price: T }[];

export const financeTableToObject = (table: FinanceInfoDisclaimerType["table"]): Record<string, string> => {
    if (!table) return {};
    return table.reduce((acc: Record<string, string>, item) => {
        if (!item) return acc;
        const itemName = item.name.toLowerCase().replace(/_([a-z])/g, (_, char) => char.toUpperCase());
        acc[itemName] = item.value;
        return acc;
    }, {});
};

export const createFinanceMinMax = <L extends { monthly: number }, T extends CreateFinanceMinMaxType<L>>(
    list: T,
): LegalBannerDisclaimerDataType | null => {
    // Create a new array to avoid mutating the existing one.
    const sortedList = list
        // Only keep the ones which have actually a disclaimer table available
        .filter((item) => item.finance?.disclaimer?.table)
        // Sort based on the numeric price value
        .sort((a, b) => {
            return a.price.monthly > b.price.monthly ? 1 : -1;
        });

    if (sortedList.length === 0) return null;

    const minMax: FinanceInfoDisclaimerType["table"][] = [sortedList[0].finance?.disclaimer?.table];

    // Only show the max if there are more than 1 item in the list.
    // *this does not cover the scenario where there could be multiple items with the same price, I never encountered such a scenario.
    if (sortedList.length > 1) {
        minMax.push(sortedList[sortedList.length - 1].finance?.disclaimer?.table);
    }

    return {
        finance: minMax.map((value) => financeTableToObject(value)),
        minMax: {
            min: minMax[0],
            max: minMax[1],
        },
        disclaimer: sortedList[0].finance?.disclaimer?.value || "",
    };
};
