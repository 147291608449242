import { useState } from "react";
import { clearModal } from "../../../../common-deprecated/redux/actions/ModalActions";
import { useCommonLabel } from "../../../../common-deprecated/utils/commonLabels";
import { useGradeSelectorDispatch, useGradeSelectorSelector } from "../redux/hooks";
import type { GradeSelectorFiltersType } from "../redux/slices/GradeSelectorFiltersSlice";
import {
    clearFilterValue,
    addColourFilterValue,
    addFilterValue,
    clearFilters,
} from "../redux/slices/GradeSelectorFiltersSlice";
import { GradeSelectorFilterId } from "../utils/filterConstants";
import { useFilteredGradeBodyTypes } from "../utils/filterHooks";
import type { FilterDataType, FilterDataToRenderType } from "../utils/filterUtils";
import {
    getResultButtonLabel,
    prepareCombinedFilterData,
    hasActiveFilters as checkActiveFilters,
} from "../utils/filterUtils";
import { MODAL_MOBILE_GRADE_SELECTOR_FILTER } from "../utils/modalConstants";
import type { GradeSelectorLabelType } from "../utils/labels";
import type { GradeBodyTypeType } from "../utils/types/Model";

type UseMobileFilterModalType = {
    closeLabel: string;
    isResultButtonDisabled: boolean;
    resultButtonLabel: GradeSelectorLabelType;
    filters: FilterDataToRenderType[];
    handleColourInput: (filterId: GradeSelectorFilterId, code: string) => void;
    handleRadioInput: (filterId: GradeSelectorFilterId, code: string) => void;
    isFilterDisabled: (filterId: GradeSelectorFilterId, filterData: FilterDataType) => boolean;
    onClose: () => void;
    confirmSelection: () => void;
    activeFilters: GradeSelectorFiltersType;
    gradeBodyTypes: GradeBodyTypeType[];
};

const useMobileFilterModal = (): UseMobileFilterModalType => {
    const dispatch = useGradeSelectorDispatch();

    const gradeBodyTypes = useFilteredGradeBodyTypes();
    const allGradeBodyTypes = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.gradeBodyTypes);
    const activeFilters = useGradeSelectorSelector((state) => state.gradeSelectorFilters);
    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);
    const gradeSelectorSettings = useGradeSelectorSelector((state) => state.gradeSelectorSettings);
    const [initialFilterState] = useState<GradeSelectorFiltersType | undefined>(activeFilters);

    const closeLabel = useCommonLabel("close");

    const isResultButtonDisabled =
        Object.keys(activeFilters).length === 0 && initialFilterState
            ? Object.keys(initialFilterState).length === 0
            : false;

    const resultButtonLabel = getResultButtonLabel(isResultButtonDisabled, gradeBodyTypes.length);

    // Prepare filter data to be rendered.
    const filters = prepareCombinedFilterData(
        gradeBodyTypes,
        allGradeBodyTypes,
        activeFilters,
        commonSettings,
        gradeSelectorSettings,
    );

    const handleColourInput = (filterId: GradeSelectorFilterId, code: string): void => {
        if (filterId === GradeSelectorFilterId.Colour) {
            if (activeFilters[filterId]?.includes(code)) {
                if (activeFilters[filterId].length === 1) {
                    dispatch(clearFilterValue({ filterId }));
                } else
                    dispatch(
                        addColourFilterValue({
                            filterId,
                            value: activeFilters[filterId].filter((codeToRemove) => codeToRemove !== code),
                        }),
                    );
            } else {
                dispatch(
                    addColourFilterValue({
                        filterId,
                        value: activeFilters[filterId] ? [...activeFilters[filterId], code] : [code],
                    }),
                );
            }
        }
    };

    const handleRadioInput = (filterId: GradeSelectorFilterId, code: string): void => {
        if (filterId !== GradeSelectorFilterId.Colour) {
            if (activeFilters[filterId] === code) {
                dispatch(clearFilterValue({ filterId }));
            } else {
                dispatch(addFilterValue({ filterId, value: code }));
            }
        }
    };

    const isFilterDisabled = (filterId: GradeSelectorFilterId, filterData: FilterDataType): boolean => {
        return !activeFilters[filterId]?.includes(filterData.code) && filterData.uniqueGradeBodyTypeIds.length === 0;
    };

    // OnClose needs to reset the filter values to their initial state.
    const onClose = (): void => {
        const hasActiveFilters = checkActiveFilters(initialFilterState);

        if (hasActiveFilters) {
            Object.values(GradeSelectorFilterId).forEach((filterId) => {
                const filter = initialFilterState?.[filterId];
                if (filter) {
                    if (Array.isArray(filter)) {
                        dispatch(
                            addColourFilterValue({
                                filterId: GradeSelectorFilterId.Colour,
                                value: filter,
                            }),
                        );
                    } else if (filterId !== GradeSelectorFilterId.Colour) {
                        dispatch(addFilterValue({ filterId, value: filter }));
                    }
                } else {
                    dispatch(clearFilterValue({ filterId }));
                }
            });
        } else {
            dispatch(clearFilters());
        }

        dispatch(clearModal(MODAL_MOBILE_GRADE_SELECTOR_FILTER));
    };

    const confirmSelection = (): void => {
        dispatch(clearModal(MODAL_MOBILE_GRADE_SELECTOR_FILTER));
    };

    return {
        closeLabel,
        filters,
        handleColourInput,
        handleRadioInput,
        isFilterDisabled,
        isResultButtonDisabled,
        onClose,
        resultButtonLabel,
        activeFilters,
        confirmSelection,
        gradeBodyTypes,
    };
};

export default useMobileFilterModal;
