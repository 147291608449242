import type { FC } from "react";
import React, { memo } from "react";
import EcoLabel from "../../../../../shared-components/css-modules/toyota/molecules/EcoLabel";
import HybridLabel from "../../../../../shared-components/css-modules/toyota/molecules/HybridLabel";
import { useGradeSelectorSelector } from "../../../../redux/hooks";
import type { DisableEcoItemType, EcoItem as EcoItemDataType } from "../../../../utils/ecoUtils";
import { getEcoItems } from "../../../../utils/ecoUtils";
import { getGradeSelectorLabel, useGradeSelectorOptionalLabel } from "../../../../utils/labels";
import type { GradeBodyTypeEngineType } from "../../../../utils/types/Model";
import styles from "./Item.module.scss";
import EfficiencyClass from "./specs/EfficiencyClass";
import type { CommonSettingsType } from "../../../../../../../common-deprecated/settings/fetchCommonSettings";
import type { GradeSelectorSettingsType } from "../../../../redux/reducers/GradeSelectorSettingsReducer";

type EcoItemType = {
    ecoItem: EcoItemDataType;
    disable: DisableEcoItemType;
    commonSettings: CommonSettingsType;
    gradeSelectorSettings: GradeSelectorSettingsType;
};

const EcoItem: FC<EcoItemType> = ({ ecoItem, disable, commonSettings, gradeSelectorSettings }) => {
    const { ecoBadgeUrl, ecoTag, ecoTagLabel, efficiencyLabelConfig } = ecoItem;
    return (
        <div className={styles["eco-item"]}>
            {efficiencyLabelConfig.show && <EfficiencyClass label={efficiencyLabelConfig.label} />}
            {ecoBadgeUrl && <EcoLabel url={ecoBadgeUrl} />}
            {!disable?.ecoTag && (
                <HybridLabel
                    variant={ecoTagLabel ? ecoTag : "default"}
                    label={getGradeSelectorLabel(
                        { commonSettings, gradeSelectorSettings },
                        ecoTagLabel || "gradeSelectorFuelTypeStandard",
                    )}
                    hideBackground
                />
            )}
        </div>
    );
};

const EcoItems: FC<{
    limit: number;
    modelId: string;
    engines: GradeBodyTypeEngineType[];
    disable?: DisableEcoItemType;
}> = ({ limit = 3, modelId, engines, disable }) => {
    const gradeSelectorSettings = useGradeSelectorSelector((state) => state.gradeSelectorSettings);
    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);
    const multiEngineLabel = useGradeSelectorOptionalLabel("multipleEngineOptions");
    const ecoItems = getEcoItems(modelId, engines, commonSettings, disable);

    return multiEngineLabel && ecoItems.length > limit ? (
        <div className={styles["eco-item"]}>
            <HybridLabel variant="multi" label={multiEngineLabel} />
        </div>
    ) : (
        <>
            {ecoItems.slice(0, limit).map((ecoItem) => (
                <div className={styles["eco-item"]} key={ecoItem.ecoTagLabel}>
                    <EcoItem
                        ecoItem={ecoItem}
                        commonSettings={commonSettings}
                        gradeSelectorSettings={gradeSelectorSettings}
                        disable={disable}
                    />
                </div>
            ))}
        </>
    );
};

export default memo(EcoItems);
