import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import type { GradeSelectorDispatchType, GradeSelectorStateType } from "./store";
import type { GradeSelectorCategoryType } from "./slices/GradeSelectorFiltersSlice";

export const useGradeSelectorDispatch = (): GradeSelectorDispatchType => useDispatch<GradeSelectorDispatchType>();
export const useGradeSelectorSelector: TypedUseSelectorHook<GradeSelectorStateType> = useSelector;

export const useGradeSelectorFilterCategory = (): (GradeSelectorCategoryType & { fullPath: string }) | undefined => {
    const categories = useGradeSelectorSelector((state) => state.gradeSelectorFilters.category);

    if (!categories || !Object.keys(categories).length) return undefined;

    const activeCategoryKey = Object.keys(categories).find((key) => categories[key].active === true);

    if (activeCategoryKey) {
        const subCategory = categories[activeCategoryKey]?.subCategory;
        return {
            ...categories[activeCategoryKey],
            fullPath: `${activeCategoryKey}${subCategory ? `/${subCategory}` : ""}`,
            root: activeCategoryKey,
        };
    }

    return undefined;
};
