import type { FC } from "react";
import React, { useCallback } from "react";
import clsx from "clsx";
import useLcvFilters from "../../../hooks/useLcvFilters";
import { useGradeSelectorSelector } from "../../../redux/hooks";
import { ProaceFilterType } from "../../../../../../common-deprecated/types/CommonTypes";
import Icon from "../../../../shared-components/css-modules/toyota/atoms/Icon";
import Button from "../../../../shared-components/css-modules/toyota/atoms/Button";
import Popover from "../../../../shared-components/css-modules/toyota/vendor/popover/Popover";
import { GradeSelectorLabel } from "../../../utils/labels";
import { useCommonLabel } from "../../../../../../common-deprecated/utils/commonLabels";
import Text from "../../../../shared-components/css-modules/toyota/atoms/Text";
import styles from "./LcvFilters.module.scss";
import { useFilteredGradeBodyTypes } from "../../../utils/filterHooks";

export const LcvFilters: FC = () => {
    const lcvFilters = useLcvFilters();
    const filteredGradeBodyTypes = useFilteredGradeBodyTypes();
    const { activeFilters, filters } = useGradeSelectorSelector((state) => state.gradeSelectorLcvFilters);
    const closeLabel = useCommonLabel("close");

    const onPopoverTriggerClick = useCallback(() => {
        lcvFilters?.setOpen((oldIsOpen) => !oldIsOpen);
    }, [lcvFilters]);

    if (!lcvFilters) return null;

    const amount = filteredGradeBodyTypes.length;

    return (
        <div>
            <Popover placement="bottom-start" open={lcvFilters.isOpen} onOpenChange={lcvFilters.setOpen} modal>
                <Popover.Trigger onClick={onPopoverTriggerClick}>
                    <Button
                        as="span"
                        variant={lcvFilters.hasActiveFilters ? "btn-default" : "clean"}
                        sizing="pill"
                        className={styles["filter-button"]}
                    >
                        <GradeSelectorLabel label="gradeSelectorFilterLcvButton" />
                        <Icon
                            variant={lcvFilters.isOpen ? "chevron-up" : "chevron-down"}
                            sizing="sm"
                            role="menu"
                            aria-hidden="true"
                        />
                    </Button>
                    <Button
                        as="span"
                        variant="clean"
                        sizing="pill"
                        className={clsx(styles["filter-button"], styles.mobile)}
                    >
                        <Icon variant="sliders" sizing="xs" role="menu" aria-hidden="true" />
                        <GradeSelectorLabel label="gradeSelectorFilterLcvButton" />
                    </Button>
                </Popover.Trigger>

                <Popover.Content variant="lcv-filter" className={styles.content}>
                    <header className={styles.header}>
                        <Popover.Close>
                            <Button variant="btn-icon-text" sizing="lg">
                                <Icon variant="close" sizing="sm" aria-hidden="true" />
                                <span>{closeLabel}</span>
                            </Button>
                        </Popover.Close>

                        <div className={styles["header-actions"]}>
                            <Button
                                variant="apheleia-text"
                                sizing="lg"
                                onClick={lcvFilters.clearFilters}
                                disabled={!lcvFilters.hasActiveFilters}
                            >
                                <GradeSelectorLabel label="gradeSelectorRemoveFilters" />
                            </Button>
                            <Popover.Close>
                                <Button variant="btn-default" disabled={!amount}>
                                    <GradeSelectorLabel
                                        label={
                                            amount === 1
                                                ? "gradeSelectorFilterSeeResult"
                                                : "gradeSelectorFilterSeeResults"
                                        }
                                        params={{ amount: `${amount}` }}
                                    />
                                </Button>
                            </Popover.Close>
                        </div>
                    </header>
                    <div className={styles["filter-items"]}>
                        {filters.map((filter) => (
                            <div
                                key={filter.code}
                                className={clsx(
                                    styles["filter-item"],
                                    filter.code === ProaceFilterType.MainBodytype ? styles.full : "",
                                )}
                            >
                                <div className={styles["filter-item-header"]}>
                                    <Text tag="h2" variant="apheleia-h4">
                                        {filter.label}
                                    </Text>
                                </div>
                                {filter.values.length > 0 && (
                                    <ul className={styles["filter-item-options"]} role="list">
                                        {filter.values.map((filterValue) => (
                                            <li
                                                className={styles["filter-item-option"]}
                                                key={`${filter.code}-${filterValue.id}`}
                                            >
                                                <div className={styles["radio-wrapper"]}>
                                                    <input
                                                        type="radio"
                                                        id={`${filter.code}-${filterValue.id}`}
                                                        name={filter.code}
                                                        disabled={
                                                            !lcvFilters.isFilterValueEnabled(
                                                                filter.code,
                                                                filterValue.id,
                                                            )
                                                        }
                                                        onChange={() =>
                                                            lcvFilters.setFilterValue(filter.code, filterValue.id)
                                                        }
                                                        checked={activeFilters[filter.code] === filterValue.id}
                                                    />
                                                    <label
                                                        htmlFor={`${filter.code}-${filterValue.id}`}
                                                        className={
                                                            filter.code === ProaceFilterType.SeatsCount
                                                                ? styles.reverse
                                                                : undefined
                                                        }
                                                    >
                                                        {filterValue.assets && (
                                                            <img
                                                                src={filterValue.assets.src}
                                                                srcSet={filterValue.assets.srcSet}
                                                                alt={filterValue.assets.alt}
                                                            />
                                                        )}
                                                        {filterValue.label}
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                </Popover.Content>
            </Popover>
        </div>
    );
};
