import type { Placement } from "@floating-ui/react";
import {
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
} from "@floating-ui/react";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useShowAfterFirstRender } from "../../../../../../../common-deprecated/hooks";

export type PopoverOptions = {
    initialOpen?: boolean;
    placement?: Placement;
    modal?: boolean;
    outsidePress?: boolean;
    ancestorScroll?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    onOpenChangeListen?: (open: boolean) => void;
};

export const SHIFT = 5; // Same as in floater
export const OFFSET = 10;

// We should not hard type it as Typescript is smart enough to do this
// later on
// eslint-disable-next-line
export const usePopover = ({
    initialOpen = false,
    placement,
    modal = true,
    outsidePress = true,
    ancestorScroll = false,
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    onOpenChangeListen,
}: PopoverOptions = {}) => {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
    const [labelId, setLabelId] = useState<string | undefined>();
    const [descriptionId, setDescriptionId] = useState<string | undefined>();

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;
    const arrowRef = useRef<HTMLDivElement | null>(null);

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [offset(OFFSET), flip(), shift({ padding: SHIFT }), arrow({ element: arrowRef })],
    });
    const arrowCallback = useCallback(
        (node: HTMLDivElement | null) => {
            arrowRef.current = node;
            data.update();
        },
        [data],
    );

    const { context } = data;

    const click = useClick(context, {
        enabled: controlledOpen == null,
    });
    const dismiss = useDismiss(context, { outsidePress, ancestorScroll });
    const role = useRole(context);

    const interactions = useInteractions([click, dismiss, role]);

    const active = useShowAfterFirstRender();
    useEffect(() => {
        if (active) {
            onOpenChangeListen?.(open);
        }
    }, [open]);

    return useMemo(
        () => ({
            open,
            setOpen,
            ...interactions,
            ...data,
            modal,
            arrowCallback,
            labelId,
            descriptionId,
            setLabelId,
            setDescriptionId,
            onOpenChangeListen,
        }),
        [open, setOpen, interactions, arrowCallback, data, modal, labelId, descriptionId, onOpenChangeListen],
    );
};

type ContextType =
    | (ReturnType<typeof usePopover> & {
          setLabelId: Dispatch<SetStateAction<string | undefined>>;
          setDescriptionId: Dispatch<SetStateAction<string | undefined>>;
      })
    | null;

export const PopoverContext = createContext<ContextType>(null);

export const usePopoverContext = (): ContextType => {
    const context = useContext(PopoverContext);

    if (context == null) {
        throw new Error("Popover components must be wrapped in <Popover />");
    }

    return context;
};
