import type { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import type {
    ImageCarConfigType,
    ImageCarType,
    ImageOptionsType,
} from "../../../../common-deprecated/settings/utils/ccisSettingUtils";
import { getCcisPath, ImageMode } from "../../../../common-deprecated/settings/utils/ccisSettingUtils";
import { getModelToken } from "../../../../common-deprecated/settings/utils/commonSettingUtils";

const getCarConfigUri = ({ packIds, accessoryIds, optionIds }: ImageCarConfigType = {}): string => {
    const packsUri = packIds && packIds.length !== 0 ? `/packs/${packIds.join(",")}` : "";
    const optionsUri = optionIds && optionIds.length > 0 ? `/options/${optionIds.join(",")}` : "";
    const accessoriesUri = accessoryIds && accessoryIds.length > 0 ? `/accessories/${accessoryIds.join(",")}` : "";

    return `${packsUri}${optionsUri}${accessoriesUri}`;
};

export const getCarImageCacheKey = (car: ImageCarType, carConfig: ImageCarConfigType = {}): string =>
    JSON.stringify({ car, carConfig });

export const getCarImage = (
    settings: CommonSettingsType,
    frame: number,
    car: ImageCarType,
    imageOptions: ImageOptionsType = {},
    carImageInput: ImageCarConfigType = {},
    mode: string = ImageMode.Exterior,
): { src: string; srcSet: string } => {
    const country = `/${settings.country}`;
    const token = getModelToken(settings, car.modelId);
    const model = token ? `/product-token/${token}/vehicle/${car.carId}` : `/vehicle/${car.modelId}/${car.carId}`;

    const getUrl = (options: ImageOptionsType): string => {
        const {
            type = "jpg",
            bgColour = "",
            scaleMode = 1,
            padding = 0,
            width = 1140,
            height = 426,
            imageQuality = 75,
        } = options;
        const backgroundUrl = bgColour ? `background-colour/${bgColour}/` : "";
        const imageOptionsUri = `/width/${width}/height/${height}/scale-mode/${scaleMode}/padding/${padding}/${backgroundUrl}image-quality/${imageQuality}/${mode}-${frame}`;
        const colors = carImageInput.colorCode
            ? `_${carImageInput.colorCode}${carImageInput.upholsteryCode ? `_${carImageInput.upholsteryCode}` : ""}`
            : "";
        const carConfigUri = getCarConfigUri(carImageInput);
        return `${getCcisPath(settings, options)}${country}${model}${carConfigUri}${imageOptionsUri}${colors}.${type}`;
    };

    const srcSet =
        imageOptions.srcSet
            ?.map((dimensions) => {
                return `${getUrl({
                    ...imageOptions,
                    width: dimensions.width,
                    height: dimensions.height,
                })} ${dimensions.width}w`;
            })
            .toString() || "";

    return { src: `${getUrl(imageOptions)}`, srcSet };
};
