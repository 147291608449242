import type { FC } from "react";
import React from "react";
import clsx from "clsx";
import { useGradeSelectorSelector } from "../../../redux/hooks";
import useLcvFilters from "../../../hooks/useLcvFilters";
import { GradeSelectorLabel } from "../../../utils/labels";
import styles from "./LcvFilters.module.scss";
import Icon from "../../../../shared-components/css-modules/toyota/atoms/Icon";
import Button from "../../../../shared-components/css-modules/toyota/atoms/Button";

export const MobileLcvFilterControls: FC = () => {
    const lcvFilters = useLcvFilters();
    const { activeFilters } = useGradeSelectorSelector((state) => state.gradeSelectorLcvFilters);
    const amount = Object.keys(activeFilters).length;

    if (!lcvFilters || !amount) return null;

    return (
        <div className={clsx(styles.header, styles["mobile-filter-controls"])}>
            <GradeSelectorLabel
                label={amount === 1 ? "gradeSelectorActiveFilter" : "gradeSelectorActiveFilters"}
                params={{ amount: `${amount}` }}
            />
            <Button variant="btn-icon-text" sizing="lg" onClick={lcvFilters?.clearFilters}>
                <GradeSelectorLabel label="gradeSelectorRemoveFilters" />
                <Icon variant="close" sizing="sm" aria-hidden="true" />
            </Button>
        </div>
    );
};
