import type { FC } from "react";
import React, { memo, useCallback } from "react";
import clsx from "clsx";
import type {
    EngineSpecType,
    GradeBodyTypeEngineTransmissionType,
    GradeBodyTypeEngineTransmissionWheeldriveType,
    GradeBodyTypeEngineType,
} from "../../../../../utils/types/Model";
import { getFullEngineCode } from "../../../../../utils/gradeUtils";
import Price from "../price/Price";
import { useGradeSelectorSelector } from "../../../../../redux/hooks";
import {
    getWLTPLabel,
    hasAlternativeEcoSpecFlow,
} from "../../../../../../../../common-deprecated/settings/utils/commonSettingUtils";
import Text from "../../../../../../shared-components/css-modules/toyota/atoms/Text";
import Icon from "../../../../../../shared-components/css-modules/toyota/atoms/Icon";
import styles from "./EngineInfo.module.scss";
import Popover from "../../../../../../shared-components/css-modules/toyota/vendor/popover/Popover";
import Button from "../../../../../../shared-components/css-modules/toyota/atoms/Button";
import {
    isTrackedEmissionSpec,
    isTrackedFuelSpec,
    trackFuelEconomyInfo,
    trackGasEmissionInfo,
} from "../../../../../utils/tracking";
import a11y from "../../../../../../shared-components/css-modules/toyota/utils/Accessibility.module.scss";
import { GradeSelectorLabel } from "../../../../../utils/labels";
import { filterEcoSpecs } from "../../../../../../../../common-deprecated/utils/specs";
import { getEcoItem } from "../../../../../utils/ecoUtils";
import EcoLabel from "../../../../../../shared-components/css-modules/toyota/molecules/EcoLabel";
import EfficiencyClass from "./EfficiencyClass";

const EngineSpecs: FC<{
    engine: GradeBodyTypeEngineType;
    transmission: GradeBodyTypeEngineTransmissionType;
    wheeldrive: GradeBodyTypeEngineTransmissionWheeldriveType;
    selectedColourId?: string;
    showPrice?: boolean;
    ariaHidden?: boolean;
    className?: string;
}> = ({ engine, transmission, wheeldrive, selectedColourId, showPrice = false, ariaHidden = false, className }) => {
    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);
    const modelId = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.id);
    const wltpLabel = getWLTPLabel(commonSettings, wheeldrive.certification, engine.fuel.electric === true);
    const alternativeEcoSpecFlow = hasAlternativeEcoSpecFlow(commonSettings);

    const eventListenerTooltip = useCallback(
        (isOpen: boolean, specCode: string) => {
            if (isOpen) {
                const fullEngineCode = getFullEngineCode(engine, transmission, wheeldrive);
                if (isTrackedFuelSpec(specCode)) {
                    trackFuelEconomyInfo(fullEngineCode);
                } else if (isTrackedEmissionSpec(specCode)) {
                    trackGasEmissionInfo(fullEngineCode);
                }
            }
        },
        [engine, transmission, wheeldrive],
    );

    const specs = alternativeEcoSpecFlow ? wheeldrive.specs : filterEcoSpecs<EngineSpecType>(wheeldrive.specs);

    const { ecoBadge, ecoBadgeUrl, efficiencyLabelConfig } = getEcoItem(modelId, engine, commonSettings);

    return (
        <div aria-hidden={ariaHidden} className={className}>
            {(!!ecoBadge || efficiencyLabelConfig.show) && (
                <div className={styles.spec}>
                    <Text spacing="spacing-0" className={styles["spec-value"]}>
                        <GradeSelectorLabel
                            label={ecoBadge ? "gradeSelectorSpecEcoBadgeTitle" : "gradeSelectorSpecEcoTagTitle"}
                        />
                    </Text>
                    <div className={clsx(styles.eco, efficiencyLabelConfig.show && styles.efficiency)}>
                        {ecoBadgeUrl ? (
                            <EcoLabel url={ecoBadgeUrl} />
                        ) : (
                            efficiencyLabelConfig.show && <EfficiencyClass label={efficiencyLabelConfig.label} />
                        )}
                    </div>
                </div>
            )}
            <div className={styles.spec}>
                <Text spacing="spacing-0" className={styles["spec-value"]}>
                    <GradeSelectorLabel label="gradeSelectorSpecWheeldrive" />
                </Text>
                <Text spacing="spacing-0" color="highlight-black" tag="div" className={styles.subtitle}>
                    <strong>{wheeldrive.name}</strong>
                </Text>
            </div>
            <div className={styles.spec}>
                <Text spacing="spacing-0" className={styles["spec-value"]}>
                    <GradeSelectorLabel label="gradeSelectorSpecTransmission" />
                </Text>
                <Text spacing="spacing-0" color="highlight-black" tag="div" className={styles.subtitle}>
                    <strong>{transmission.name}</strong>
                </Text>
            </div>

            {specs.map((spec) => (
                <div className={styles.spec} key={spec.id}>
                    <div>
                        <Text spacing="spacing-0" className={styles["spec-value"]}>
                            {spec.short || spec.name}

                            {(wltpLabel || spec.tooltip) && (
                                <Popover
                                    placement="top"
                                    onOpenChangeListen={(isOpen: boolean) => eventListenerTooltip(isOpen, spec.code)}
                                >
                                    <Popover.Trigger>
                                        <Button
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            }}
                                            variant="clean"
                                            type="button"
                                            className={styles.info}
                                        >
                                            <Icon variant="info" sizing="sm" aria-hidden="true" />
                                            <span className={a11y["sr-only"]}>
                                                <GradeSelectorLabel label="ariaToggleFuelInfo" />
                                            </span>
                                        </Button>
                                    </Popover.Trigger>
                                    <Popover.Content>
                                        <div dangerouslySetInnerHTML={{ __html: spec.tooltip || wltpLabel }} />
                                    </Popover.Content>
                                </Popover>
                            )}
                        </Text>
                    </div>
                    <Text spacing="spacing-0" color="highlight-black" tag="div" className={styles.subtitle}>
                        <strong>
                            {spec.value} {spec.unit}
                        </strong>
                    </Text>
                </div>
            ))}

            {showPrice && (
                <div className={styles.spec}>
                    <Text spacing="spacing-0" className={styles["spec-value"]}>
                        <GradeSelectorLabel label="gradeSelectorSpecEnginePrice" />
                    </Text>
                    <Text color="highlight-black" tag="div" className={styles.subtitle}>
                        <strong className={styles.price}>
                            <Price wheeldrive={wheeldrive} selectedColourId={selectedColourId} column />
                        </strong>
                    </Text>
                </div>
            )}
        </div>
    );
};

export default memo(EngineSpecs);
