import type { Dispatch, SetStateAction } from "react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useGradeSelectorSelector } from "../redux/hooks";
import type { GradeSelectorDispatchType } from "../redux/store";
import {
    resetGradeSelectorLcvFilters,
    setGradeSelectorLcvFilterValue,
} from "../redux/slices/GradeSelectorLcvFiltersSlice";
import useGradeSelectorModal from "./useGradeSelectorModal";
import { MODAL_MOBILE_GRADE_SELECTOR_LCV_FILTER } from "../utils/modalConstants";
import { isMobileView } from "../../../../common-deprecated/utils";
import { filterLcvFilterCars } from "../utils/filterUtils";
import type { LcvFilterCarType } from "../utils/types/LcvFilter";
import { trackResetFilters } from "../utils/tracking";

export type UseLcvFiltersType = {
    enabled: boolean;
    hasActiveFilters: boolean;
    filteredCars: LcvFilterCarType[];
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setFilterValue: (filterCode: string, value: string) => void;
    isFilterValueEnabled: (filterCode: string, value: string) => boolean;
    clearFilters: () => void;
};

const useLcvFilters = (): UseLcvFiltersType | null => {
    const { filters, initialized, enabledFilterValues, activeFilters, cars } = useGradeSelectorSelector(
        (state) => state.gradeSelectorLcvFilters,
    );
    const { showModalHelper } = useGradeSelectorModal();
    const isMobile = isMobileView();

    const [isOpen, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch<GradeSelectorDispatchType>();
    const enabled = filters.length > 0 && initialized;

    useEffect(() => {
        if (isOpen && isMobile) {
            showModalHelper(MODAL_MOBILE_GRADE_SELECTOR_LCV_FILTER);
        }
        // No extra deps because otherwise the modal is opened forever
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, isOpen]);

    /**
     * Memo this value because filterLcvFilterCars is compute heavy and we want to run this as little as possible
     */
    const filteredCars = useMemo(() => {
        return filterLcvFilterCars(cars, activeFilters);
    }, [activeFilters, cars]);

    return useMemo(() => {
        if (!enabled) return null;
        return {
            enabled,
            hasActiveFilters: !!Object.keys(activeFilters).length,
            filteredCars,
            isOpen,
            setOpen,
            setFilterValue: (filterCode: string, value: string) =>
                dispatch(setGradeSelectorLcvFilterValue({ filterCode, value })),
            isFilterValueEnabled: (filterCode: string, value: string): boolean => {
                if (Object.keys(enabledFilterValues).length === 0) return true;
                return (enabledFilterValues[filterCode] || []).includes(value);
            },
            clearFilters: () => {
                trackResetFilters();
                dispatch(resetGradeSelectorLcvFilters());
            },
        };
    }, [enabled, activeFilters, filteredCars, isOpen, dispatch, enabledFilterValues]);
};

export default useLcvFilters;
