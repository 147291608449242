import type { PropsWithChildren } from "react";
import React from "react";
import clsx from "clsx";
import styles from "./Container.module.scss";

type Props = {
    className?: string;
    variant?: "default" | "full-width";
} & React.HTMLProps<HTMLDivElement>;

const Container: React.FC<PropsWithChildren<Props>> = ({ variant = "default", className, children, ...rest }) => {
    return (
        <div className={clsx(styles[variant], className)} {...rest}>
            {children}
        </div>
    );
};

export default Container;
