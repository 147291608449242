import { useMemo } from "react";
import { useGradeSelectorSelector } from "../redux/hooks";
import { selectModel } from "../redux/slices/GradeSelectorModelSlice";
import type { GradeBodyTypeType } from "./types/Model";
import { applyFilters, applyLcvFilters, hasActiveFilters as checkActiveFilters, mergeBodyTypes } from "./filterUtils";
import useLcvFilters from "../hooks/useLcvFilters";

export const useFilteredGradeBodyTypes = (): GradeBodyTypeType[] => {
    const activeFilters = useGradeSelectorSelector((state) => state.gradeSelectorFilters);
    const lcvFilters = useLcvFilters();

    const hasActiveFilters = checkActiveFilters(activeFilters);

    const { gradeBodyTypes } = useGradeSelectorSelector(selectModel);

    return useMemo(() => {
        if (lcvFilters && lcvFilters.hasActiveFilters) {
            return applyLcvFilters(gradeBodyTypes, lcvFilters.filteredCars);
        } else {
            if (!hasActiveFilters) {
                return gradeBodyTypes;
            }

            return applyFilters(activeFilters, gradeBodyTypes);
        }
    }, [lcvFilters, gradeBodyTypes, hasActiveFilters, activeFilters]);
};

// Retailer variant of useFilteredGradeBodyTypes, additional sorting is needed here.
export const useRetailerFilteredGradeBodyTypes = (hasFilter: boolean): GradeBodyTypeType[] => {
    const allGradeBodyTypes = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.gradeBodyTypes);
    const filteredGradeBodyTypes = useFilteredGradeBodyTypes();

    // Important useMemo to reduce amount of rerenders.
    const sortedBodyTypes = useMemo(() => {
        return mergeBodyTypes(allGradeBodyTypes);
    }, [allGradeBodyTypes]);

    return hasFilter ? filteredGradeBodyTypes : sortedBodyTypes;
};
