import type { RefObject } from "react";
import { useCallback, useRef } from "react";

type Output<T extends HTMLElement> = {
    handleMouseDown: (event: React.MouseEvent<HTMLElement>) => void;
    handleMouseUp: () => void;
    handleMouseMove: (event: React.MouseEvent<HTMLElement>) => void;
    ref: RefObject<T>;
};

export const useDrag = <T extends HTMLElement>(): Output<T> => {
    const ref = useRef<T>(null);
    const dragging = useRef(false);
    const startX = useRef(0);

    const handleMouseDown = useCallback((event: React.MouseEvent<HTMLElement>) => {
        dragging.current = true;
        startX.current = event.clientX;
    }, []);

    const handleMouseUp = useCallback(() => {
        dragging.current = false;
    }, []);

    const handleMouseMove = useCallback((event: React.MouseEvent<HTMLElement>) => {
        if (!dragging.current || !ref.current) return;

        const deltaX = event.clientX - startX.current;
        ref.current.scrollLeft -= deltaX;
        startX.current = event.clientX;
    }, []);

    return { handleMouseDown, handleMouseUp, handleMouseMove, ref };
};
