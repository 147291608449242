import React, { memo } from "react";
import clsx from "clsx";
import type { IconVariantTypes } from "./Icon.types";
import type { ColorTypes } from "../utils/ColorTypes";
import styles from "./Icon.module.scss";
import colorStyles from "../utils/ColorTypes.module.scss";

export type Props = {
    className?: string;
    variant: IconVariantTypes;
    sizing?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
    color?: ColorTypes;
} & React.HTMLProps<SVGSVGElement>;

const Icon: React.FC<Props> = ({ variant, sizing, color, className, ...rest }) => {
    // /* Includes all icons in the sprite sheet */
    // TODO: only include used icons, use import instead of require
    // eslint-disable-next-line global-require,import/no-dynamic-require
    require(`../assets/sprites/${variant}.svg`);

    const iconClass = `icon-${variant}`;

    return (
        <svg
            className={clsx(
                styles.icon,
                sizing && styles[sizing],
                color && colorStyles[color],
                styles[iconClass],
                className,
            )}
            aria-hidden="true"
            {...rest}
        >
            <use xlinkHref={`#${variant}`} />
        </svg>
    );
};

export default memo(Icon);
