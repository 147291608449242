import type { FC } from "react";
import React, { memo, useState } from "react";
import buttonStyles from "./FilterButton.module.scss";
import styles from "./Filter.module.scss";
import { GradeSelectorFilterId } from "../../../../../utils/filterConstants";
import FilterButton from "./FilterButton";
import { GradeSelectorLabel, useGradeSelectorLabel } from "../../../../../utils/labels";
import Icon from "../../../../../../shared-components/css-modules/toyota/atoms/Icon";
import { useGradeSelectorDispatch, useGradeSelectorSelector } from "../../../../../redux/hooks";
import { clearFilters } from "../../../../../redux/slices/GradeSelectorFiltersSlice";
import Button from "../../../../../../shared-components/css-modules/toyota/atoms/Button";
import {
    hasActiveFilters as checkActiveFilters,
    hasActiveFilter as checkActiveFilter,
    getFilterData,
    prepareCombinedFilterData,
} from "../../../../../utils/filterUtils";
import { useFilteredGradeBodyTypes } from "../../../../../utils/filterHooks";
import { MODAL_MOBILE_GRADE_SELECTOR_FILTER } from "../../../../../utils/modalConstants";
import useGradeSelectorModal from "../../../../../hooks/useGradeSelectorModal";

const Filter: FC = () => {
    const dispatch = useGradeSelectorDispatch();
    const filteredGradeBodyTypes = useFilteredGradeBodyTypes();
    const { showModalHelper } = useGradeSelectorModal();

    const activeFilters = useGradeSelectorSelector((state) => state.gradeSelectorFilters);
    const gradeBodyTypes = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.gradeBodyTypes);
    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);
    const gradeSelectorSettings = useGradeSelectorSelector((state) => state.gradeSelectorSettings);
    const navigationLabel = useGradeSelectorLabel("ariaFilterNavigation");

    const [showAllFilters, setShowAllFilters] = useState(false);

    const hasActiveFilters = checkActiveFilters(activeFilters);

    const filters = Object.values(GradeSelectorFilterId)
        .map((filterId) => {
            const hasActiveFilter = checkActiveFilter(filterId, activeFilters);

            // Checks combined filter data of filtered gradeBodyTypes & all gradeBodyTypes.
            let filterData = prepareCombinedFilterData(
                filteredGradeBodyTypes,
                gradeBodyTypes,
                activeFilters,
                commonSettings,
                gradeSelectorSettings,
                filterId,
            )[0]?.data;

            if (!filterData) {
                filterData = getFilterData(filterId, filteredGradeBodyTypes, commonSettings, gradeSelectorSettings);
            }

            // Don't show filter buttons if they only contain 1 value.
            // But always show active filters.
            if (filterData.length < 2 && !hasActiveFilter) return null;

            return (
                <FilterButton
                    key={filterId}
                    filterId={filterId}
                    activeFilters={activeFilters}
                    filterData={filterData}
                    hasActiveFilter={hasActiveFilter}
                />
            );
        })
        .filter(Boolean); // Filter out the null values.

    return (
        <nav className={styles.filters} aria-label={navigationLabel}>
            {/* Mobile button: */}
            <Button
                onClick={() => showModalHelper(MODAL_MOBILE_GRADE_SELECTOR_FILTER)}
                className={`${buttonStyles["filter-button-wrapper"]} ${buttonStyles["filter-button-mobile"]}`}
                variant="clean"
            >
                <Icon variant="controls" sizing="sm" aria-hidden="true" />
                <GradeSelectorLabel label="gradeSelectorAllFiltersButton" />
            </Button>

            {/* Desktop only: */}
            <Button
                onClick={() => hasActiveFilters && dispatch(clearFilters())}
                className={`${buttonStyles["filter-button-wrapper"]} ${
                    !hasActiveFilters ? `${buttonStyles.active} ${buttonStyles.disabled}` : ""
                }`}
                variant="clean"
            >
                <GradeSelectorLabel label="gradeSelectorFilterAllGradesButton" />
            </Button>

            {/* Only show the first 2 filter buttons, unless showAllFilters is true. */}
            {filters.slice(0, showAllFilters ? Object.values(GradeSelectorFilterId).length : 2)}

            {/* Only display showMore button if there are more filters to be displayed. */}
            {filters.length > 2 && (
                <Button
                    onClick={() => setShowAllFilters(!showAllFilters)}
                    className={`${buttonStyles["filter-button-wrapper"]} ${showAllFilters ? buttonStyles.active : ""}`}
                    variant="clean"
                >
                    <Icon variant="controls" sizing="sm" aria-hidden="true" />
                    <GradeSelectorLabel
                        label={showAllFilters ? "gradeSelectorFilterShowLess" : "gradeSelectorFilterShowMore"}
                    />
                </Button>
            )}
        </nav>
    );
};

export default memo(Filter);
