import type { PropsWithChildren } from "react";
import React from "react";
import clsx from "clsx";
import type { ColorTypes } from "../utils/ColorTypes";
import type { SpacingTypes } from "../utils/SpacingTypes";
import spacingStyles from "../utils/SpacingTypes.module.scss";
import colorStyles from "../utils/ColorTypes.module.scss";
import styles from "./List.module.scss";

export type Props = {
    tag?: "ul" | "ol";
    variant?: "default" | "checkmarks" | "checkmarks-reverse";
    color?: ColorTypes;
    spacing?: SpacingTypes;
    className?: string;
    sizing?: "sm";
} & React.HTMLProps<HTMLUListElement>;

const List: React.FC<PropsWithChildren<Props>> = (props) => {
    const { tag = "ul", variant = "default", color, sizing, spacing, className, children, ...rest } = props;

    return React.createElement(
        tag,
        {
            ...rest,
            role: "list",
            className: clsx(
                styles[variant],
                color && colorStyles[color],
                sizing && styles[sizing],
                spacing && spacingStyles[spacing],
                className,
            ),
        },
        children,
    );
};

export default List;
