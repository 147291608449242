import React, { memo } from "react";
import clsx from "clsx";
import { useUniqueId } from "../../../../../../common-deprecated/hooks";
import type { SpacingTypes } from "../utils/SpacingTypes";
import spacingStyles from "../utils/SpacingTypes.module.scss";
import styles from "./Checkbox.module.scss";
import Swatch from "../atoms/Swatch";
import Text from "../atoms/Text";

export type Props = {
    className?: string;
    label: string; // Label of the checkbox.
    swatch?: { asset?: string; rgb?: string }; // Optional swatch asset.
    hideLabel?: boolean; // Give label a sr-only class.
    variant?: "default";
    spacing?: SpacingTypes;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Checkbox: React.FC<Props> = ({ label, swatch, variant = "default", hideLabel, spacing, className, ...rest }) => {
    const uniqueId = useUniqueId();

    return (
        <div className={clsx(styles[variant], spacing && spacingStyles[spacing], className)}>
            <input id={uniqueId} type="checkbox" {...rest} />
            <label htmlFor={uniqueId} className={clsx(hideLabel && styles["sr-only"])}>
                {swatch && <Swatch id={uniqueId} image={swatch?.asset} fill={swatch?.rgb} />}
                <Text>{label}</Text>
            </label>
        </div>
    );
};

export default memo(Checkbox);
