import type { FC } from "react";
import React from "react";
import clsx from "clsx";
import Text from "../../../../../../shared-components/css-modules/toyota/atoms/Text";
import EcoItems from "../EcoItems";
import styles from "./EngineSelect.module.scss";
import Icon from "../../../../../../shared-components/css-modules/toyota/atoms/Icon";
import type { MotorizationItemType } from "../../../../../utils/gradeUtils";
import EngineSpecs from "./EngineSpecs";

type EngineSelectProps = {
    motorization: MotorizationItemType;
    modelId: string;
    setSelectedMotorization?: (motorization: MotorizationItemType) => void;
    active?: boolean;
    summary?: boolean;
    showSpecs?: boolean;
};

const EngineSelect: FC<{ props: EngineSelectProps }> = ({ props }) => {
    const {
        motorization,
        modelId,
        setSelectedMotorization,
        active = false,
        summary = false,
        showSpecs = false,
    } = props;

    return (
        // This should be a button accessibility wise. But this would result in a button inside a button, which creates hydration errors.
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
            onClick={setSelectedMotorization && (() => setSelectedMotorization(motorization))}
            className={clsx(styles.wrapper, summary && styles.summary, active && styles.active)}
        >
            <div className={styles["top-section"]}>
                <div className={styles.text}>
                    <EcoItems
                        modelId={modelId}
                        engines={[motorization.engine]}
                        limit={2}
                        disable={{ ecoBadge: true, efficiencyLabel: true }}
                    />
                    <Text>{motorization.engine.name}</Text>
                    <Text>{`${motorization.wheeldrive.name}, ${motorization.transmission.name}`}</Text>
                </div>
                {!summary && active && (
                    <div className={styles.icon}>
                        <Icon sizing="xs" variant="check" aria-hidden="true" />
                    </div>
                )}
            </div>

            {showSpecs && (
                <EngineSpecs
                    ariaHidden
                    engine={motorization.engine}
                    transmission={motorization.transmission}
                    wheeldrive={motorization.wheeldrive}
                    className={styles.specs}
                />
            )}
        </div>
    );
};

export default EngineSelect;
