export enum FuelSpecCode {
    FuelCombined = "fuelcombinedwltp",
    FuelCombinedMax = "fuelcombinedwltpmax",
    FuelCombinedUfWeighted = "fuelcombinedufweightedwltp",
    FuelCombinedNormalWltp = "fuelcombinednormalwltp",
    FuelCombinedCs = "fuelcombinedcswltp",
}

export enum EmissionSpecCode {
    Co2Combined = "co2combinedwltp",
    Co2CombinedNormalWltp = "co2combinednormalwltp",
    Co2CombinedCsWltp = "co2combinedcswltp",
    Co2CombinedUfWeightedWltp = "co2combinedufweightedwltp",
    Co2CombinedMax = "co2combinedwltpmax",
}

export enum EvSpecCode {
    Range = "percombined",
    RangeMax = "percombinedmax",
    City = "percity",
    CityMax = "percitymax",
    ChargingTime = "340be24d-6d82",
    PowerConsumption = "a500d7cf-7bcf",
    PowerConsumptionWeighted = "b89ac81c-6da8",
    PowerConsumptionWeightedMax = "c29f621b-6ea6",
    ElectricCombinedRangeMax = "eaercombinedkmmax",
    ElectricCombinedRange = "eaercombinedkm",
    ElectricCityRangeMax = "eaercitykmmax",
    ElectricCityRange = "eaercitykm",
}

export enum HydrogenSpecCode {
    FuelCombinedKg = "fuelcombinedwltpkg",
    FuelCombinedKgMax = "fuelcombinedwltpkgmax",
}
