import React, { memo, useCallback } from "react";
import type { EcoLabelPopupLabelType } from "../../../../../../../../common-deprecated/types/SettingsType";
import EmissionLabelComponent from "../../../../../../../../common-deprecated/components/eco-labels/EmissionLabelComponent";
import styles from "./EfficiencyClass.module.scss";

type PropsType = {
    label: EcoLabelPopupLabelType;
    hideInfoIcon?: boolean;
};

const EfficiencyClass = (props: PropsType): JSX.Element | null => {
    const onClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }, []);

    return (
        /* Disable because this is only a prevention and not an actual click for the user */
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
        <span onClick={onClick} className={styles.wrapper}>
            <EmissionLabelComponent {...props} />
        </span>
    );
};

export default memo(EfficiencyClass);
