import React, { memo } from "react";
import clsx from "clsx";
import { useUniqueId } from "../../../../../../common-deprecated/hooks";
import type { SpacingTypes } from "../utils/SpacingTypes";
import spacingStyles from "../utils/SpacingTypes.module.scss";
import styles from "./Radio.module.scss";
import Text from "../atoms/Text";

export type Props = {
    className?: string;
    label: string | JSX.Element; // Label of the radio.
    amount?: number;
    hideLabel?: boolean; // Give label a sr-only class.
    variant?: "default";
    spacing?: SpacingTypes;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Radio: React.FC<Props> = ({ label, amount, variant = "default", hideLabel, spacing, className, ...rest }) => {
    const uniqueId = useUniqueId();

    return (
        <div className={clsx(styles[variant], spacing && spacingStyles[spacing], className)}>
            <input id={uniqueId} type="radio" {...rest} />
            <label htmlFor={uniqueId} className={clsx(hideLabel && styles["sr-only"])}>
                <Text>
                    {label} <span>{amount}</span>
                </Text>
            </label>
        </div>
    );
};

export default memo(Radio);
