import type { FC } from "react";
import React from "react";
import {
    formatAEMCashPriceIntl,
    getAEMLicenseFee,
    getAemPriceConfig,
} from "../../../../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import {
    AemFmComponent,
    AemFmPriceType,
} from "../../../../../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { useGradeSelectorSelector } from "../../../../../redux/hooks";
import { hasDiscount } from "../../../../../utils/priceHooks";
import type { ApiPriceType } from "../../../../../../../../common-deprecated/types/ApiModel";
import { hideDiscountInfo } from "../../../../../../../../common-deprecated/priceUtils";
import { usePromotions } from "../../../../../utils/disclaimerHooks";
import styles from "./Price.module.scss";
import Popover from "../../../../../../shared-components/css-modules/toyota/vendor/popover/Popover";
import Button from "../../../../../../shared-components/css-modules/toyota/atoms/Button";
import Icon from "../../../../../../shared-components/css-modules/toyota/atoms/Icon";
import { calculatePriceWithColour } from "../../../../../utils/gradeUtils";

const CashPrice: FC<{
    price: ApiPriceType;
    colourPrice?: ApiPriceType;
    showPromotionsNumbers?: boolean;
}> = ({ price, colourPrice, showPromotionsNumbers = false }) => {
    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);
    const modelCode = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.code);
    const cashPriceDisclaimer = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.cashPriceDisclaimer);
    const priceConfig = getAemPriceConfig(commonSettings, AemFmComponent.GradeSelector, modelCode);
    const filteredPromotions = usePromotions(price.discounts?.map((item) => item.id));

    hideDiscountInfo(priceConfig);

    const priceToUse =
        priceConfig.priceType === AemFmPriceType.InclVat ? price.listWithDiscount : price.netWithDiscount;

    if (!priceConfig.priceEnabled || !priceToUse) {
        return null;
    }

    const licenseFee = getAEMLicenseFee(commonSettings, priceToUse, modelCode, AemFmComponent.GradeSelector);
    const showTooltip = !!cashPriceDisclaimer && licenseFee > 0;

    const priceWithColour = calculatePriceWithColour(
        priceConfig.priceType === AemFmPriceType.InclVat,
        priceToUse,
        colourPrice,
    );

    const currentPrice = formatAEMCashPriceIntl(priceWithColour, commonSettings, priceConfig, licenseFee, true);
    const hideDiscount = hideDiscountInfo(priceConfig);

    if (hasDiscount(price) && !hideDiscount) {
        const oldPriceToUse = priceConfig.priceType === AemFmPriceType.InclVat ? price.list ?? 0 : price.net ?? 0;
        const oldPriceWithColour = calculatePriceWithColour(
            priceConfig.priceType === AemFmPriceType.InclVat,
            oldPriceToUse,
            colourPrice,
            true,
        );

        const oldPrice = formatAEMCashPriceIntl(oldPriceWithColour, commonSettings, priceConfig, licenseFee, true);

        return (
            <span>
                <s dangerouslySetInnerHTML={{ __html: oldPrice.primaryPrice }} />
                <span dangerouslySetInnerHTML={{ __html: currentPrice.primaryPrice }} />
                {oldPrice.secondaryPrice && <s dangerouslySetInnerHTML={{ __html: oldPrice.primaryPrice }} />}
                {currentPrice.secondaryPrice && (
                    <span>
                        {" "}
                        /
                        <span dangerouslySetInnerHTML={{ __html: currentPrice.secondaryPrice }} />
                    </span>
                )}
                {showPromotionsNumbers && filteredPromotions?.length !== 0 && (
                    <span className={styles.numbers}>
                        {filteredPromotions.map(({ index }) => (
                            <span className={styles.number} key={index}>
                                {index}
                            </span>
                        ))}
                    </span>
                )}
            </span>
        );
    }
    return (
        <span className={styles.wrapper}>
            <span dangerouslySetInnerHTML={{ __html: currentPrice.primaryPrice }} />
            {currentPrice.secondaryPrice && (
                <span>
                    {" "}
                    / <span dangerouslySetInnerHTML={{ __html: currentPrice.secondaryPrice }} />
                </span>
            )}
            {showTooltip && (
                <span className={styles.popover}>
                    <Popover>
                        <Popover.Trigger>
                            <Button variant="clean" type="button">
                                <Icon variant="info" sizing="sm" aria-hidden="true" />
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content>{cashPriceDisclaimer}</Popover.Content>
                    </Popover>
                </span>
            )}
        </span>
    );
};

export default CashPrice;
