import type { FC } from "react";
import React from "react";

import { GradeSelectorMemoLabel } from "../../../utils/labels";
import Slider from "./slider/Slider";
import { selectModel } from "../../../redux/slices/GradeSelectorModelSlice";
import { useGradeSelectorSelector } from "../../../redux/hooks";
import Text from "../../../../shared-components/css-modules/toyota/atoms/Text";
import Container from "../../../../shared-components/css-modules/toyota/atoms/Container";
import styles from "./selector.module.scss";
import ExchangeRate from "./exchange-rate/ExchangeRate";
import Disclaimers from "./disclaimers/Disclaimers";
import { useBodyScrollbarWidth } from "../../../hooks/useBodyScrollbarWidth";

const Selector: FC = () => {
    const { name } = useGradeSelectorSelector(selectModel);
    const scrollbarWidth = useBodyScrollbarWidth();
    return (
        <section className={styles.wrapper} style={{ "--or-scrollbar-size": scrollbarWidth }}>
            <Container>
                <Text tag="h2" spacing="spacing-4" className={styles.slidertitle}>
                    <GradeSelectorMemoLabel label="gradeSelectorMainTitle" params={{ model: name }} useHTML />
                </Text>
                <Text tag="h3" spacing="spacing-4" className={styles.slidersubtitle}>
                    <GradeSelectorMemoLabel label="gradeSelectorMainSubtitle" useHTML />
                </Text>
            </Container>
            <Slider />
            <Container>
                <ExchangeRate />
            </Container>
            <Container>
                <Disclaimers />
            </Container>
            <Container>
                <div className="section gf-inpage-gradeselector" />
            </Container>
        </section>
    );
};

export default Selector;
