import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { LcvFilterInfoType } from "../../utils/types/LcvFilter";
import { getFilteredFilterValues } from "../../utils/filterUtils";

export type GradeSelectorLcvFiltersType = LcvFilterInfoType & {
    initialized: boolean;
    enabledFilterValues: Record<string, string[]>;
    activeFilters: Record<string, string>;
};

export const initialState: GradeSelectorLcvFiltersType = {
    initialized: false,
    cars: [],
    filters: [],
    activeFilters: {},
    enabledFilterValues: {},
};

export const gradeSelectorLcvFiltersSlice = createSlice({
    name: "gradeSelectorLcvFilters",
    initialState,
    reducers: {
        initGradeSelectorLcvFilters(state, action: PayloadAction<LcvFilterInfoType | null>) {
            return {
                ...state,
                ...action.payload,
                initialized: true,
            };
        },
        setGradeSelectorLcvFilterValue(state, action: PayloadAction<{ value: string; filterCode: string }>) {
            state.activeFilters = getFilteredFilterValues(action.payload, state.activeFilters);
        },
        setGradeSelectorLcvFilterEnabledValues(state, action: PayloadAction<Record<string, string[]>>) {
            state.enabledFilterValues = action.payload;
        },
        resetGradeSelectorLcvFilters(state) {
            state.activeFilters = {};
        },
    },
});

export const {
    initGradeSelectorLcvFilters,
    setGradeSelectorLcvFilterValue,
    setGradeSelectorLcvFilterEnabledValues,
    resetGradeSelectorLcvFilters,
} = gradeSelectorLcvFiltersSlice.actions;

export type GradeSelectorLcvFiltersActionTypes =
    | ReturnType<typeof initGradeSelectorLcvFilters>
    | ReturnType<typeof setGradeSelectorLcvFilterValue>
    | ReturnType<typeof setGradeSelectorLcvFilterEnabledValues>
    | ReturnType<typeof resetGradeSelectorLcvFilters>;
