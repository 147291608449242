import type {
    CommonFinanceInfoType,
    ExtraType,
    PricePromotionType,
    PriceType,
} from "../../../common-deprecated/types/CommonTypes";
import type { BasketType } from "../types/BasketType";
import type { CarType } from "../types/CarType";
import { getDefaultPriceObject } from "../../../common-deprecated/constants";
import { find } from "../../../common-deprecated/utils";
import type { CommonMonthlyFinanceInfoLabelKey, FormatMonthlyPriceType } from "../../../common-deprecated/priceUtils";
import { CommonMonthlyPriceLabelKey, formatMonthlyPrice } from "../../../common-deprecated/priceUtils";
import { formatPrice } from "../../../common-deprecated/Globalize";
import type { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";

/**
 * Helper method which creates a single list of all promotions, preventing duplicate entries
 */
export const addPromotions = (
    targetPromotions: PricePromotionType[],
    newPromotions: PricePromotionType[],
): PricePromotionType[] =>
    targetPromotions.concat(newPromotions.filter((newPromo) => !find(newPromo.id, targetPromotions)));
/**
 * Combine multiple price objects into a single price.
 * This implementation has a lot of assumptions and should be used with care, especially for monthly rates.
 * @param prices - Array of price objects
 */
export const addPrices = (prices: PriceType[]): PriceType => {
    const finalPrice = { ...getDefaultPriceObject() };

    prices.forEach((price) => {
        finalPrice.monthly += price.monthly;
        finalPrice.cash += price.cash;
        finalPrice.discount += price.discount;
        finalPrice.exclVat += price.exclVat;
        finalPrice.highlight = finalPrice.highlight || price.highlight || false;
        finalPrice.promotions = addPromotions(finalPrice.promotions, price.promotions);
        finalPrice.onlineCashDiscount += price.onlineCashDiscount;
        finalPrice.onlineMonthlyDiscount += price.onlineMonthlyDiscount;
        finalPrice.onlineCashPromotions = addPromotions(finalPrice.onlineCashPromotions, price.onlineCashPromotions);
        finalPrice.onlineMonthlyPromotions = addPromotions(
            finalPrice.onlineMonthlyPromotions,
            price.onlineMonthlyPromotions,
        );
    });

    return finalPrice;
};

/**
 * Get a price from an extra. This will add the extra price and the selected option price.
 * If no selectedOptionId is available the default id from the extra will be used.
 * @param extra
 * @param selectedOptionId
 */
export const getPriceFromExtra = (extra: ExtraType, selectedOptionId?: string): PriceType => {
    const { options, price } = extra;

    const optionId = selectedOptionId || extra.selectedOptionId;

    if (options.length) {
        const selectedOption = find(optionId, options);
        const activeOption = selectedOption || extra.options[0];

        return addPrices([price, activeOption.price]);
    }
    return extra.price;
};

/**
 * Method which calculates the total price object
 * @param initialTotal
 * @param basket
 * @param car
 * @param showMonthlyRates
 */
export const calculateTotalPrice = (
    initialTotal: PriceType,
    basket: BasketType,
    car: CarType,
    showMonthlyRates: boolean,
): PriceType => {
    // Loop through every basket option except for total and add prices.
    const calculatedTotal: PriceType = Object.keys(basket).reduce(
        (total, basketKey) => {
            // Cast to any since not all basket properties have the price property defined
            // Check to fix this. Seems very hacky
            const basketElement = (basket as any)[basketKey];
            if (basketKey !== "total" && basketElement) {
                if (Array.isArray(basketElement)) {
                    basketElement.forEach((item) => {
                        if (item.price) {
                            const itemPrice = basketKey === "extras" ? getPriceFromExtra(item) : item.price;
                            total = addPrices([total, itemPrice]);
                        }
                    });
                } else if (basketElement && basketElement.price) {
                    total = addPrices([total, basketElement.price]);
                    if (
                        basketKey === "winterWheel" &&
                        basketElement.installTheWheels &&
                        basketElement.installationCost
                    ) {
                        total = addPrices([total, basketElement.installationCost]);
                    }
                }
            }
            return total;
        },
        { ...initialTotal },
    );
    // Also add car data.
    if (car.price) {
        calculatedTotal.cash += car.price.cash;
        calculatedTotal.discount += car.price.discount;
        calculatedTotal.promotions = addPromotions(calculatedTotal.promotions, car.price.promotions);
        calculatedTotal.exclVat += car.price.exclVat;
        calculatedTotal.onlineCashDiscount += car.price.onlineCashDiscount;
        calculatedTotal.onlineMonthlyDiscount += car.price.onlineMonthlyDiscount;
        calculatedTotal.onlineCashPromotions = addPromotions(
            calculatedTotal.onlineCashPromotions,
            car.price.onlineCashPromotions,
        );
        calculatedTotal.onlineMonthlyPromotions = addPromotions(
            calculatedTotal.onlineMonthlyPromotions,
            car.price.onlineMonthlyPromotions,
        );
    }
    // Sort the promo based on the index of each item
    calculatedTotal.promotions.sort((a, b) => a.index - b.index);
    // Reuse old monthlyRate until the new one is fetched. (see process function).
    if (showMonthlyRates) {
        calculatedTotal.monthly = basket.total.monthly || 0;
    }
    if (basket.userSelectedPromotions.length > 0) {
        basket.userSelectedPromotions.forEach((promo) => {
            if (promo.amount) {
                calculatedTotal.discount = (calculatedTotal.discount || 0) - promo.amount;
                calculatedTotal.promotions = addPromotions(calculatedTotal.promotions, [promo]);
                calculatedTotal.onlineCashDiscount = (calculatedTotal.onlineCashDiscount || 0) - promo.amount;
                calculatedTotal.onlineMonthlyDiscount = (calculatedTotal.onlineMonthlyDiscount || 0) - promo.amount;
                calculatedTotal.onlineCashPromotions = addPromotions(calculatedTotal.onlineCashPromotions, [promo]);
                calculatedTotal.onlineMonthlyPromotions = addPromotions(calculatedTotal.onlineMonthlyPromotions, [
                    promo,
                ]);
            }
        });
    }
    return { ...calculatedTotal };
};

export const monthlyPriceFormatting = (
    priceExclPromo: string,
    priceFormat: string,
    financeInfo: CommonFinanceInfoType,
): Partial<Record<CommonMonthlyPriceLabelKey, string>> =>
    financeInfo.productType === "Leasing" && priceFormat.includes("[leasingPrice]")
        ? {
              [CommonMonthlyPriceLabelKey.LeasingPrice]: priceExclPromo,
              [CommonMonthlyPriceLabelKey.Price]: "",
          }
        : {
              [CommonMonthlyPriceLabelKey.Price]: priceExclPromo,
              [CommonMonthlyPriceLabelKey.LeasingPrice]: "",
          };

/**
 * Formats financing monthly prices, ideally this should not be used for anything else (like insurance in the past)
 */

export const formatMonthlyFinancePrice = (
    price: PriceType,
    financeInfo: CommonFinanceInfoType,
    commonSettings: CommonSettingsType,
    priceFormat: string,
    rateWithoutPromotions?: number,
): FormatMonthlyPriceType => {
    const term = financeInfo.term || financeInfo.duration || "";
    const tan = financeInfo.annualInterestRate || financeInfo.interestRate;

    const financeInfoReplacements: Record<CommonMonthlyFinanceInfoLabelKey, string> = {
        term,
        productName: financeInfo.productName || "",
        taeg: financeInfo.effectiveInterestRate || "",
        tan: tan || "",
        // residualValue and downPayment are price values, so apply formatPrice as well.
        residualValue: financeInfo.residualValue
            ? formatPrice(financeInfo.residualValue, commonSettings.culture.name, true)
            : "",
        downPayment: financeInfo.downPayment
            ? formatPrice(financeInfo.downPayment, commonSettings.culture.name, true)
            : "",
        totalAmountFinanced: financeInfo.totalAmountFinanced
            ? formatPrice(financeInfo.totalAmountFinanced, commonSettings.culture.name, true)
            : "",
        amountFinanced: financeInfo.amountFinanced
            ? formatPrice(financeInfo.amountFinanced, commonSettings.culture.name, true)
            : "",
        totalCreditWithFees: financeInfo.totalCreditCostWithFee
            ? formatPrice(financeInfo.totalCreditCostWithFee, commonSettings.culture.name, true)
            : "",
        mileage: financeInfo.mileage || "",
        lastInstalment: financeInfo.lastInstalment || "",
        installments: financeInfo.installments || "",
        disclaimer: "", // This is supported for USC components but not for NC for now.
    };

    return formatMonthlyPrice(
        price.monthly,
        financeInfo,
        commonSettings,
        priceFormat,
        financeInfoReplacements,
        (priceExclPromo: string) => monthlyPriceFormatting(priceExclPromo, priceFormat, financeInfo),
        rateWithoutPromotions,
    );
};
