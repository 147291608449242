import { useState } from "react";
import { useGradeSelectorDispatch } from "../redux/hooks";
import type { GradeSelectorFiltersType } from "../redux/slices/GradeSelectorFiltersSlice";
import { clearFilterValue, addFilterValue, addColourFilterValue } from "../redux/slices/GradeSelectorFiltersSlice";
import { GradeSelectorFilterId } from "../utils/filterConstants";
import type { FilterDataType } from "../utils/filterUtils";
import {
    getFilterButtonLabel,
    getFilterResultAmount,
    getResultButtonLabel,
    isResultButtonDisabled,
} from "../utils/filterUtils";
import { useCommonLabel } from "../../../../common-deprecated/utils/commonLabels";
import type { GradeSelectorLabelType } from "../utils/labels";
import { trackResetFilters } from "../utils/tracking";

type UseFilterButtonType = {
    onOpenChange: () => void;
    handleOpen: () => void;
    clearFilters: () => void;
    handleColourInput: (id: string) => void;
    addFilter: () => void;
    selectedFilters: string[];
    setSelectedFilters: (filters: string[]) => void;
    closeLabel: string;
    isButtonDisabled: boolean;
    isOpen: boolean;
    filterResultAmount: number;
    resultButtonLabel: GradeSelectorLabelType;
    buttonLabel: GradeSelectorLabelType;
    buttonText: string;
};

const useFilterButton = (
    filterId: GradeSelectorFilterId,
    activeFilters: GradeSelectorFiltersType | undefined,
    filterData: FilterDataType[],
): UseFilterButtonType => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]); // Keeps track of the selected but not yet confirmed filters.
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useGradeSelectorDispatch();

    const closeLabel = useCommonLabel("close");

    const isButtonDisabled = isResultButtonDisabled(filterId, selectedFilters, activeFilters);
    const filterResultAmount = getFilterResultAmount(filterId, filterData, selectedFilters);
    const resultButtonLabel = getResultButtonLabel(isButtonDisabled, filterResultAmount);
    const buttonLabel = getFilterButtonLabel(filterId);
    const buttonText =
        filterData.find(({ code }) => activeFilters?.[filterId]?.toString() === code)?.name ?? buttonLabel;

    // Manipulates the components local filter states. This needs to update whenever the Popover opens or closes.
    const onOpenChange = (): void => {
        // Get active checkbox filter data.
        if (
            filterId === GradeSelectorFilterId.Colour &&
            activeFilters?.colour?.toString() !== selectedFilters?.toString()
        ) {
            setSelectedFilters(activeFilters?.colour ? [...activeFilters.colour] : []);
        }

        // Get active radio filter data.
        if (activeFilters && filterId !== GradeSelectorFilterId.Colour) {
            const radioFilterData = filterData.findIndex((data) => data.code === activeFilters[filterId]);
            if (radioFilterData !== -1) {
                setSelectedFilters([radioFilterData.toString()]);
            } else {
                setSelectedFilters([]);
            }
        }
    };

    const handleOpen = (): void => {
        setIsOpen(!isOpen);
    };

    const clearFilters = (): void => {
        trackResetFilters();
        dispatch(clearFilterValue({ filterId }));
    };

    const handleColourInput = (id: string): void => {
        if (selectedFilters.includes(id)) {
            setSelectedFilters([...selectedFilters.filter((colour) => colour !== id)]);
        } else {
            setSelectedFilters([...selectedFilters, id]);
        }
    };

    const addFilter = (): void => {
        if (filterId !== GradeSelectorFilterId.Colour) {
            dispatch(addFilterValue({ filterId, value: filterData[Number(selectedFilters)].code }));
        } else if (selectedFilters.length && filterId === GradeSelectorFilterId.Colour) {
            dispatch(
                addColourFilterValue({
                    filterId,
                    value: filterData
                        .filter((data) => data.code && selectedFilters.includes(data.code))
                        .map((data) => data.code),
                }),
            );
        } else if (filterId === GradeSelectorFilterId.Colour && filterResultAmount === 0) {
            dispatch(clearFilterValue({ filterId }));
        }

        setIsOpen(!isOpen);
    };

    return {
        addFilter,
        clearFilters,
        handleColourInput,
        handleOpen,
        onOpenChange,
        selectedFilters,
        setSelectedFilters,
        resultButtonLabel,
        buttonLabel,
        buttonText,
        closeLabel,
        filterResultAmount,
        isButtonDisabled,
        isOpen,
    };
};

export default useFilterButton;
